import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Angulartics2Module } from 'angulartics2';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

Sentry.init({
  ...environment.sentryApi,
  ignoreErrors: [
    'Invariant Violation',
    // google maps error
    /maps\/api/, /MapsRequestError/, /GEOCODER_GEOCODE/,
    'cdn.clearroadlab.io'
  ],
  integrations: [
    new Sentry.Integrations.TryCatch({
      XMLHttpRequest: false
    }),
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],
  tracesSampleRate: 0.5
});

// capture loading chunk errors
const chunkFailedMessage = /Loading chunk [\d]+ failed/;

const clearCaches = async () => {
  const keys = await caches.keys();
  return Promise.all(keys.map(caches.delete));
};

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    console.error(error);
    if (chunkFailedMessage.test(error.message)) {
      clearCaches().then(window.location.reload);
    }
    else {
      Sentry.captureException(error.originalError || error);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule, HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: 'cr_cama'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    Angulartics2Module.forRoot(),
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    CameraPreview,
    ImagePicker,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
