<ion-header>
  <ion-toolbar color="primary">
    <a class="d-inline-block align-top" routerDirection="root" [routerLink]="programsService.url('')">
      <img src="assets/img/full-logo.png" class="d-inline-block align-top" alt="">
    </a>
    <ion-buttons slot="end">
      <ion-button [routerLink]="programsService.url('users/signin')">
        <span i18n="@@signin">Sign In</span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="header-bg">
    <div class="header-backdrop">
      <div class="container px-4 px-md-3"></div>
    </div>
  </div>

  <div class="container my-5 text-muted">
    <h1>404 Not Found</h1>
    <p>The page you are looking for does not exists.</p>
    <a routerDirection="root" [routerLink]="programsService.url('')">
      <span>Return to the home page</span>
    </a>
  </div>

  <footer class="bg-primary position-relative">
    <div class="container py-5">
      <hr class="border-white pt-5" />
      <app-footer color="light"></app-footer>
    </div>
  </footer>
</ion-content>
