<ion-grid class="ion-padding-vertical">
  <ion-row class="ion-padding-vertical ion-align-items-center ion-justify-content-between">
    <ion-col size="auto" *ngIf="showHome">
      <a routerDirection="root" [routerLink]="programsService.url('')">
        <ion-text [color]="color"><p>ClearRoadlab</p></ion-text>
      </a>
    </ion-col>
    <ion-col size="auto">
      <a routerDirection="root" [routerLink]="programsService.url('privacypolicy')">
        <ion-text [color]="color">
          <p>Privacy policy</p>
        </ion-text>
      </a>
    </ion-col>
    <ion-col size="auto">
      <a routerDirection="root" [routerLink]="programsService.url('tandc')">
        <ion-text [color]="color"><p>Terms and conditions</p></ion-text>
      </a>
    </ion-col>
  </ion-row>
  <hr class="border-white pt-2" />
  <ion-row>
    <ion-col size="6">
      <ion-text color="light" class="publication-text">
        <p>© {{ now.getFullYear() }} ClearRoad, Inc</p>
      </ion-text>
    </ion-col>
    <ion-col size="6">
      <ion-text color="light" class="publication-text ion-float-right">
        <p>29 Norman Avenue, Brooklyn, NY 11222</p>
      </ion-text>
    </ion-col>
  </ion-row>
</ion-grid>
