import { Component, Input } from '@angular/core';

import { ProgramsService } from '../../programs/programs.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input()
  color = 'medium';
  @Input()
  showHome = true;

  public now = new Date();

  constructor(
    public programsService: ProgramsService
  ) {}
}
