import { IEnv } from './environment.interface';

export const environment: IEnv = {
  production: true,
  googleAnalytics: {
    enabled: true,
    key: 'UA-131116096-3'
  },
  api: {
    baseUrl: 'https://api.clearroadev.xyz/cam'
  },
  authApi: {
    accessToken: 'CH6DrZrtGqmUsUDRodUAvrEVuAaYqMJZC5ULk8zNvLNs',
    baseUrl: 'https://api.clearroadev.xyz/auth'
  },
  odometerApi: {
    baseUrl: 'https://api.clearroadev.xyz/odometer'
  },
  vinApi: {
    accessToken: '4zKPyBR1KFsujggaueURbH8esWrGzywaGNkCxMJaN6Lc',
    baseUrl: 'https://api.clearroadev.xyz/vin'
  },
  hereApi: {
    id: '1jNke1nVayWVMmjyoLqh',
    code: '54vvRc_q778iJXd9znBqiw'
  },
  stripeApi: {
    key: 'pk_test_j9SG60XDcwCfL1IMUFGu6my4001UEaNiMC'
  },
  sentryApi: {
    dsn: 'https://49d5d845b7a24f91aa0c22f15623f810@o373258.ingest.sentry.io/5569369',
    release: 'cam-app@fd2cc22471a3d8e59ac79db354fc9f30bc002833',
    environment: 'staging'
  }
};
