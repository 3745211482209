import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
const storageKey = 'cam_dm';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public darkThemeEnabled = false;

  constructor(private storage: Storage) {}

  public async init() {
    const isEnabled = await this.storage.get(storageKey);
    if (isEnabled === true || prefersDark.matches) {
      this.updateTheme(isEnabled);
    }
    else if (isEnabled === null) {
      prefersDark.addListener(({matches}) => this.updateTheme(matches));
    }
  }

  public async updateTheme(enabled) {
    this.darkThemeEnabled = enabled;
    await this.storage.set(storageKey, enabled);
    document.body.classList.toggle('dark', enabled);
  }
}
