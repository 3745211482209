import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationExtras } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Program, User, DeviceProviders } from '@clearroadlab/cam';

import { environment } from '../../environments/environment';
import { handleAPIError } from '../utils';
import { UsersService } from '../users/users.service';

const path = `${environment.api.baseUrl}/programs`;

const keyFromUrl = (url: string) => (keys: string[]) => {
  const paths = url.split('/');
  const key = url.startsWith('/') ? paths[1] : paths[0];
  return keys.includes(key) ? key : null;
};

/**
 * Check if Odometer service is enabled for the program
 *
 * @param program {Program} The program
 * @param primary {boolean} True to check only for primary provider.
 */
export const odometerEnabled = (program: Program, primary = false) => {
  const config = program.vehicleTrips.deviceProviders[DeviceProviders.ClearRoad];
  return config.primary || (!primary && config.secondary);
};

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {
  private _keys = new ReplaySubject<string[]>(1);
  private programKey: string;

  private user: User;
  // for terms/privacy pages
  public static: any;
  public defaultStatic: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private usersService: UsersService
  ) {
    this.loadAll();
    this.usersService.user$.subscribe(user => this.user = user);
  }

  private async loadAll() {
    const keys = await this.http.get<string[]>(`${path}/keys`).toPromise();
    this._keys.next(keys);
    // set program initially from current url
    this.getConfigKeyFromUrl(this.router.url);
  }

  public get keys$() {
    return this._keys.asObservable();
  }

  public navigate(commands: any[] = [], extras?: NavigationExtras) {
    return this.router.navigate(['/', this.key, ...commands].filter(Boolean), extras);
  }

  public url(url: string) {
    return this.key ? `/${this.key}/${url}` : `/${url}`;
  }

  public get key() {
    return this.user ? this.user.programKey : this.programKey || '';
  }

  public get headers() {
    return {
      'X-PROGRAM-KEY': this.key
    };
  }

  public getConfigKeyFromUrl(url: string) {
    return this.keys$.pipe(
      map(keyFromUrl(url)),
      tap(key => this.programKey = key)
    );
  }

  public validateCode(code: string) {
    return this.http.post<string>(`${path}/codes/${code}`, {}).toPromise().catch(handleAPIError);
  }

  public validateCodeAsync(code: string) {
    return this.http.post<string>(`${path}/codes/${code}`, {});
  }

  public get() {
    return this.http.get<Program>(path, {
      headers: this.headers
    }).toPromise().catch(handleAPIError);
  }

  public async all() {
    return this.http.get<{ results: Program[] }>(`${path}/all`, {
      headers: await this.usersService.headers(),
      params: { limit: 9999 }
    }).toPromise().catch(handleAPIError);
  }

  // Static data

  public getStatic(headers?: any) {
    return this.http.get<any>(`${path}/static`, {
      headers: headers || this.headers
    }).toPromise().catch(handleAPIError);
  }

  private async getStaticValue() {
    const value = this.static || await this.getStatic();
    this.static = value;
    return value;
  }

  private async getDefaultStaticValue() {
    const value = this.defaultStatic || await this.getStatic({});
    this.defaultStatic = value;
    return value;
  }

  public async getStaticContent(key: string) {
    return (await this.getStaticValue())[key] || (await this.getDefaultStaticValue())[key];
  }
}
