const get = require('lodash.get');

const defaultHost = 'clearroadlab.io';
const host = window.location.host.indexOf(defaultHost) !== -1 ? defaultHost : 'clearroadev.xyz';
export const baseUrl = `${window.location.protocol}//www.${host}`;
export const developerBaseUrl = `${window.location.protocol}//developer.${host}`;
export const typeformUrl = 'https://clearroad.typeform.com/to/qnCMTc';

export const currentLocationLabel = 'Current Location';

const parseErrorStatus = (error) => (error?.statusText || '').toLowerCase().replace(/\s/g, '-');

const parseErrorMessage = (error) =>
  get(error, 'error.error', get(error, 'error.message', get(error, 'error', get(error, 'message', error))));

export const handleAPIError = error => {
  try {
    error = parseErrorMessage(error);
  }
  catch (_) { }
  throw error;
};

export const errorText = (error: any): string => {
  if (typeof error === 'string') {
    return error;
  }
  const err = parseErrorMessage(error);
  return parseErrorStatus(err) || err;
};

export const toFormData = (data: any, formData = new FormData()) => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (typeof value === 'object') {
        formData.append(key, JSON.stringify(value));
      }
      else {
        formData.append(key, value);
      }
    }
  }
  return formData;
};

export const filterParams = <T>(obj: T) => {
  const res: Partial<T> = {};
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (value) {
      res[key] = value;
    }
  });
  return res;
};

export const sameMonth = (d1: string|Date, d2: string|Date) => {
  d1 = new Date(d1);
  d2 = new Date(d2);
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth();
};

export const sameDay = (d1: string|Date, d2: string|Date) => {
  d1 = new Date(d1);
  d2 = new Date(d2);
  return sameMonth(d1, d2) && d1.getDate() === d2.getDate();
};

export const startOfDay = (now?: Date) => {
  if (!now) {
    return undefined;
  }
  const date = new Date(now);
  date.setHours(0, 0, 0, 0);
  return date.toISOString();
};

export const endOfDay = (now?: Date) => {
  if (!now) {
    return undefined;
  }
  const date = new Date(now);
  date.setHours(23, 59, 59, 99);
  return date.toISOString();
};

export const yearsMonthsDays = (date: Date|string) => {
  const to = typeof date === 'string' ? date : new Date(date).toISOString();
  return to.substring(0, 10);
};

export const yearsMonths = (date: Date|string) => yearsMonthsDays(date).substring(0, 7);

export const beginningOfDay = (date: string) => `${date}T00:00:00.000Z`;

export const previoustMonth = (month: number) => month === 1 ? 12 : month - 1;
const previousYear = (year: number, month: number) => month === 1 ? year - 1 : year;

export const nextMonth = (month: number) => month === 12 ? 1 : month + 1;
const nextYear = (year: number, month: number) => month === 12 ? year + 1 : year;

export const currentMonth = `${yearsMonths(new Date())}-01`;

export const lastMonth = (now: Date|string = new Date(), num = 1) => {
  const date = new Date(now);
  date.setMonth(date.getMonth() - num);
  return date;
};

export const yesterday = (now: Date|string = new Date()) => {
  const date = new Date(now);
  date.setDate(date.getDate() - 1);
  return date;
};

/**
 * Change the date by how many months
 *
 * @param date {string} The date in YYYY-mm-dd format
 * @param diff {number}
 */
export const changeMonth = (date: string, diff: number) => {
  const [ years, months, days ] = date.split('-').map(v => parseInt(v, 10));
  const mapp = [];
  for (let i = 0; i < Math.abs(diff); i++) {
    mapp.push(0);
  }
  return mapp.reduce((prev, _curr) => [
      diff > 0 ? nextYear(prev[0], prev[1]) : previousYear(prev[0], prev[1]),
      diff > 0 ? nextMonth(prev[1]) : previoustMonth(prev[1]),
      days
    ], [
    years,
    months,
    days
  ]).map(v => v < 10 ? `0${v}` : v).join('-');
};

export const day = 24 * 60 * 60 * 1000;

export const dateRange = (date1: Date, date2 = new Date()) => {
  const days: Date[] = [];
  for (let i = date1.getTime(); i < date2.getTime(); i += day) {
    days.push(new Date(i));
  }
  return days;
};

export const dateWithoutTimezone = (date: Date|string|number) => {
  date = new Date(date);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
  return date;
};

const gantrySize = 10;
const gantryRatio = 2;

const gantry = (color: string, orientation: 'horizontal'|'vertical' = 'horizontal') => {
  const width = orientation === 'horizontal' ? gantrySize * gantryRatio : gantrySize;
  const height = orientation === 'horizontal' ? gantrySize : gantrySize * gantryRatio;
  return `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
    <rect stroke="${color}" fill="${color}" x="0" y="0" width="${width}" height="${height}" />
  </svg>`;
};

const colors = {
  Entry: 'rgba(163, 68, 85, 0.7)',
  Exit: 'rgba(229, 96, 120, 0.7)',
  PassThrough: 'rgba(163, 0, 30, 0.7)'
};

export const intersectionIcon = {
  Entry: {
    Northbound: gantry(colors.Entry),
    Southbound: gantry(colors.Entry),
    Eastbound: gantry(colors.Entry, 'vertical'),
    Westbound: gantry(colors.Entry, 'vertical')
  },
  Exit: {
    Northbound: gantry(colors.Exit),
    Southbound: gantry(colors.Exit),
    Eastbound: gantry(colors.Exit, 'vertical'),
    Westbound: gantry(colors.Exit, 'vertical')
  },
  PassThrough: {
    Northbound: gantry(colors.PassThrough),
    Southbound: gantry(colors.PassThrough),
    Eastbound: gantry(colors.PassThrough, 'vertical'),
    Westbound: gantry(colors.PassThrough, 'vertical')
  }
};
