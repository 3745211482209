import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { UsersService } from './users.service';
import { ProgramsService } from '../programs/programs.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuardService implements CanActivate {
  constructor(
    private router: Router,
    private usersService: UsersService,
    private programsService: ProgramsService
  ) {}

  canActivate(_r, { url }: RouterStateSnapshot) {
    return this.usersService.user$.pipe(
      mergeMap(user =>
        user ? this.programsService.getConfigKeyFromUrl(url).pipe(
          map(key => this.router.parseUrl(key ? `/${key}/programs` : '/users'))
        ) : of(true)
      )
    );
  }
}
