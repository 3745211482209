import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertController, LoadingController } from '@ionic/angular';

// check for update every 5 minutes
const checkUpdateInterval = 5 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class SwUpdateService {
  private promptUpdateDisabled = false;
  private checkForUpdateInterval;

  constructor(
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private swUpdate: SwUpdate
  ) {
    this.swUpdate.available.subscribe(event => {
      if (event.current.hash !== event.available.hash) {
        this.promptUpdate();
      }
    });
  }

  async checkForUpdate(checkInterval = false) {
    if (!this.swUpdate.isEnabled) {
      return;
    }
    await this.swUpdate.checkForUpdate();
    if (!checkInterval) {
      return;
    }

    this.checkForUpdateInterval = setInterval(() => {
      this.swUpdate.checkForUpdate();
    }, checkUpdateInterval);
  }

  private async promptUpdate() {
    if (this.promptUpdateDisabled) {
      return;
    }

    const alert = await this.alertCtrl.create({
      header: 'Update Available',
      message: 'A new version is available. Please update now to have access to the latest functionalities.',
      buttons: [{
        text: 'Not Now',
        role: 'cancel',
        handler: () => {
          this.promptUpdateDisabled = true;
          clearInterval(this.checkForUpdateInterval);
        }
      }, {
        text: 'Update Now!',
        handler: () => {
          this.activateUpdate();
        }
      }]
    });
    await alert.present();
  }

  private async activateUpdate() {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();
    await this.swUpdate.activateUpdate();
    await loading.dismiss();
    document.location.reload();
  }
}
