import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';

import { DistanceComponent } from './distance/distance.component';
import { FooterComponent } from './footer/footer.component';
import { UnitComponent } from './unit/unit.component';
import { MilesPipe } from './miles.pipe';
import { KeysPipe } from './keys.pipe';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [
    MilesPipe,
    KeysPipe,
    DistanceComponent,
    FooterComponent,
    UnitComponent,
    HeaderComponent,
  ],
  exports: [
    MilesPipe,
    KeysPipe,
    DistanceComponent,
    FooterComponent,
    UnitComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: '6LfkMF0UAAAAAEBBobf2JZvK7PjdQ298a-fvOrTe' } as RecaptchaSettings
  }]
})
export class SharedModule { }
