import { Component } from '@angular/core';

import { ProgramsService } from '../programs/programs.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent {
  constructor(
    public programsService: ProgramsService
  ) {}
}
