import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { User, IUserCreate } from '@clearroadlab/cam';
import { configureScope, captureException } from '@sentry/browser';

import { environment } from '../../environments/environment';
import { handleAPIError } from '../utils';

const path = `${environment.api.baseUrl}/users`;
const profileStorageKey = 'up';
const tokenStorageKey = 'ut';

export const generatePassword = () => Math.random().toString(36).slice(-8);

export const passwordPattern = '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\\D*\\d)[A-Za-z\\d!$%@#£€*?&]{6,}$';

export class ConfirmPasswordValidator {
  static match(control: AbstractControl) {
    const password = control.get('password').value;
    const confirm = control.get('passwordConfirm').value;
    return password !== confirm ? { matchPassword: true } : null;
  }
}

const sameUser = (a, b) => JSON.stringify(a) === JSON.stringify(b);

const handleError = (error: Error) => {
  if (
    error.message.indexOf('users index: email') !== -1 ||
    error.message.indexOf('users Failed _id or unique key constraint') !== -1
  ) {
    throw new Error('users-email-already-taken');
  }
  if (
    error.message.indexOf('vehicles index: vin') !== -1
  ) {
    throw new Error('vehicles-vin-already-taken');
  }
  throw error;
};

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private _user = new ReplaySubject<User>(1);
  private _token = '';

  constructor(
    private http: HttpClient,
    private storage: Storage
  ) {
    this.getCurrentUser();
  }

  async getCurrentUser() {
    try {
      this._token = await this.token();
      this._user.next(await this.storage.get(profileStorageKey));
      // fetch user to make sure data is up to date
      await this.reloadUser();
    }
    catch (_err) {
      // if we are using a wrong token
      if (this._token) {
        await this.signOut();
      }
    }
  }

  async updateCurrentUser(user: User) {
    const { token, ...profile } = user;
    if (!sameUser(await this.storage.get(profileStorageKey), profile)) {
      this._user.next(profile);
      await this.storage.set(profileStorageKey, profile);
    }
    this._token = token;
    await this.storage.set(tokenStorageKey, token);
    configureScope(scope => {
      scope.setUser({
        id: user.id,
        email: user.email
      });
    });
  }

  private async reloadUser() {
    const user = await this.get();
    if (user) {
      await this.updateCurrentUser(user);
    }
  }

  public get user$() {
    return this._user.asObservable();
  }

  public get loggedInUser() {
    return this._user.pipe(take(1)).toPromise();
  }

  private async token() {
    const token = await this.storage.get(tokenStorageKey);
    return token as string;
  }

  async headers() {
    return {
      'X-ACCESS-TOKEN': await this.token()
    };
  }

  public create(data: IUserCreate) {
    return this.http.post<User>(path, data).toPromise().catch(handleAPIError).catch(handleError);
  }

  async delete(user: Partial<User>) {
    return this.http.delete(`${path}/${user.email}`, {
      headers: await this.headers()
    }).toPromise().catch(handleAPIError);
  }

  async signIn(user: Partial<User>) {
    const res = await this.http.post<User>(`${path}/signin`, user).toPromise().catch(handleAPIError);
    try {
      await this.updateCurrentUser(res);
    }
    catch (err) {
      captureException(err);
    }
  }

  async signOut() {
    this._user.next(null);
    await this.storage.remove(profileStorageKey);
    await this.storage.remove(tokenStorageKey);
    setTimeout(() => window.location.href = '');
  }

  async resendConfirm(user: Partial<User>) {
    return this.http.post<User>(`${path}/confirm/send`, user).toPromise().catch(handleAPIError);
  }

  async get() {
    try {
      return await this.http.get<User>(`${path}/me`, {
        headers: await this.headers()
      }).toPromise().catch(handleAPIError);
    }
    catch (err) {
      if (err.message === 'Unauthorized') {
        await this.signOut();
      }
      else {
        throw err;
      }
    }
  }

  async balance() {
    return await this.http.get<{ balance: number }>(`${path}/balance`, {
      headers: await this.headers()
    }).toPromise().catch(handleAPIError);
  }

  async update(user: Partial<User>) {
    await this.http.put<User>(`${path}/me`, user, {
      headers: await this.headers()
    }).toPromise().catch(handleAPIError).catch(handleError);
    await this.reloadUser();
  }

  async updatePassword(user: Partial<User>, newPassword: string) {
    const data = { ...user, newPassword };
    return this.http.put<User>(`${path}/password`, data).toPromise().catch(handleAPIError);
  }

  async resetPassword(user: Partial<User>) {
    return this.http.post<User>(`${path}/password/reset`, user).toPromise().catch(handleAPIError);
  }

  async list() {
    return this.http.get(path, {
      headers: await this.headers(),
      params: {
        admin: true,
        limit: 999999
      }
    }).toPromise().catch(handleAPIError);
  }
}
