import { Component } from '@angular/core';
import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { User } from '@clearroadlab/cam';

declare let ga;

import { environment } from '../environments/environment';
import { ProgramsService } from './programs/programs.service';
import { UsersService } from './users/users.service';
import { developerBaseUrl } from './utils';
import { SwUpdateService } from './sw-update.service';
import { ThemeService } from './theme.service';

const waitForGA = (callback: () => void) => {
  if ('ga' in window) {
    return callback();
  }
  setTimeout(() => waitForGA(callback), 100);
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public user: User;
  public baseUrl = developerBaseUrl;

  constructor(
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    public platform: Platform,
    private menuCtrl: MenuController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private swUpdateService: SwUpdateService,
    public programsService: ProgramsService,
    public themeService: ThemeService,
    public usersService: UsersService
  ) {
    this.initializeApp();
    waitForGA(() => {
      ga('create', environment.googleAnalytics.key, environment.googleAnalytics.enabled ? 'auto' : 'none');
      angulartics2GoogleAnalytics.startTracking();
    });
    this.usersService.user$.subscribe(user => this.updateUser(user));
  }

  private async initializeApp() {
    await this.platform.ready();
    await this.themeService.init();
    this.statusBar.styleDefault();
    this.splashScreen.hide();
    await this.swUpdateService.checkForUpdate();
    this.menuCtrl.swipeGesture(this.user ? true : false);
  }

  private async updateUser(user: User) {
    this.user = user;
    return this.menuCtrl.enable(!!user, 'main');
  }

  logout() {
    return this.usersService.signOut();
  }
}
