import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { User } from '@clearroadlab/cam';

import { ProgramsService } from './programs.service';
import { UsersService } from '../users/users.service';

const programUrl = (siteKey: string, url: string) => (user: User) =>
  user?.programKey ? `/${user.programKey}${(url === '/' ? '' : url).replace(`/${siteKey}`, '')}` : null;

@Injectable({
  providedIn: 'root'
})
export class ProgramsGuardService implements CanActivate {
  constructor(
    private router: Router,
    private usersService: UsersService,
    private programsService: ProgramsService
  ) {}

  private toUrl(url: string) {
    return this.programsService.getConfigKeyFromUrl(url).pipe(
      mergeMap(siteKey => this.usersService.user$.pipe(
        map(programUrl(siteKey, url))
      ))
    );
  }

  canActivate(_: ActivatedRouteSnapshot, { url }: RouterStateSnapshot) {
    return this.toUrl(url).pipe(
      map(toUrl => toUrl !== null && url !== toUrl ? this.router.parseUrl(toUrl) : true)
    );
  }
}
