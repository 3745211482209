<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu type="overlay" contentId="main-content" menuId="main">
      <ion-header class="ion-no-border">
        <div id="header">
          <img class="logo logo-light" src="assets/img/full-logo.png" />
          <img class="logo logo-dark" src="assets/img/logo.svg" />
          <ion-text color="dark">
            <h5 class="bold-600">{{ user?.name }}</h5>
          </ion-text>
          <ion-text color="medium">
            <p id="label-email" class="text-sm">{{ user?.email }}</p>
          </ion-text>
        </div>
      </ion-header>
      <ion-content>
        <div id="horizontal-line"></div>
        <ion-list>
          <ion-menu-toggle auto-hide="false" *ngIf="programsService.key">
            <ion-item
              lines="none"
              detail="false"
              routerDirection="root"
              [routerLink]="programsService.url('programs')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="today"></ion-icon>
              <ion-label>
                <span i18n="@@programs.show.title">My Program</span>
              </ion-label>
            </ion-item>
            <ion-item
              lines="none"
              detail="false"
              routerDirection="root"
              [routerLink]="programsService.url('vehicles')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="car"></ion-icon>
              <ion-label>
                <span i18n="@@vehicles.list.title">My Vehicles</span>
              </ion-label>
            </ion-item>
            <ion-item
              lines="none"
              detail="false"
              routerDirection="root"
              [routerLink]="programsService.url('vehicle-trips')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="map"></ion-icon>
              <ion-label>
                <span i18n="@@vehicle-trips.list.title">My Trips</span>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item
              lines="none"
              detail="false"
              routerDirection="root"
              [routerLink]="programsService.url('addresses')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="bookmarks"></ion-icon>
              <ion-label>
                <span i18n="@@addresses.list.title">My Address</span>
              </ion-label>
            </ion-item>
            <ion-item
              lines="none"
              detail="false"
              routerDirection="root"
              [routerLink]="programsService.url('users')"
              routerLinkActive="selected"
              *ngIf="user && user.admin">
              <ion-icon slot="start" name="people"></ion-icon>
              <ion-label>
                <span i18n="@@users.list.title">Users</span>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer no-border *ngIf="user">
        <ion-list class="ion-no-margin">
          <!-- <ion-item lines="none" detail="false">
            <ion-label>
              <h2>{{ user.name }}</h2>
            </ion-label>
          </ion-item> -->

          <ion-menu-toggle auto-hide="false">
            <ion-item
              lines="none"
              detail="false"
              routerDirection="root"
              [routerLink]="programsService.url('users/profile')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="person"></ion-icon>
              <ion-label>
                <span i18n="@@users.profile.title">My Profile</span>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false" *ngIf="platform.is('mobile')">
            <ion-item
              lines="none"
              detail="false"
              routerDirection="root"
              [routerLink]="programsService.url('about')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="information-circle"></ion-icon>
              <ion-label>
                <span i18n="@@about.title">About</span>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-item lines="none" detail="false" *ngIf="user.admin && platform.is('desktop')" [href]="baseUrl + '/admin/'" target="_blank">
            <ion-icon slot="start" name="construct"></ion-icon>
            <ion-label>
              <span i18n="@@admin">Admin</span>
            </ion-label>
          </ion-item>

          <ion-item lines="none" detail="false" (click)="logout()" pointer>
            <ion-icon slot="start" name="log-out"></ion-icon>
            <ion-label>
              <span i18n="@@logout">Sign Out</span>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
